import { graphql, useStaticQuery } from 'gatsby'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Img from 'gatsby-image/withIEPolyfill'
import InputGroup from 'src/components/InputGroup'
import Button from 'src/components/Button'
import React, { forwardRef } from 'react'
import LeadCapture from 'src/components/LeadCapture/LeadCapture'
import { CountdownEmailSupport } from 'src/components/Countdown'

const FreeProgramLeadCapture = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hvco-free-weight-loss-programme.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <LeadCapture
      title={`FREE Programme Spells Out Exactly How To Lose 1 kg/week
              With Your Own, Fully-Customised Weight-Loss Plan!`}
      subtitle={`Attention: Dieters Fed Up Of Not Seeing Progress`}
      bullets={
        <>
          <Typography variant={'h6'} as={'p'}>
            This ridiculously simple, <strong>FREE</strong> weight-loss plan is
            customised to meet your weight-loss goals.
          </Typography>
          <Box as={'ul'} css={{ paddingInlineStart: '24px' }}>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Includes <strong>FREE email support!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn the forgotten secrets of{' '}
              <strong>how to beat cravings for good!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn the hidden secrets of fasting and how to{' '}
              <strong>never feel hungry!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how to{' '}
              <strong>lose weight as fast as humanly possible!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how to lose weight{' '}
              <strong>without stepping foot in a gym!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how to <strong>finally stop the yo-yo dieting!</strong>
            </Typography>
          </Box>
        </>
      }
      rhs={
        <Box width={{ md: '100%' }}>
          <Img fluid={data.file.childImageSharp.fluid} objectFit={'cover'} />
        </Box>
      }
      countdown={<CountdownEmailSupport countdown={6} len={20} />}
      formName={'lead-capture-free-program'}
      formAction={'/thank-you/hvco'}
      formFields={
        <>
          <InputGroup
            name={'full-name'}
            id={'full-name'}
            labelText={'Full Name'}
            placeholder={'Full Name'}
            type={'text'}
          />
          <InputGroup
            name={'email'}
            id={'email'}
            labelText={'Email'}
            placeholder={'Email'}
            type={'email'}
          />
          <Button mt={'0.5em'} width={1} type={'submit'}>
            Let’s Get Started
          </Button>
        </>
      }
      {...props}
    />
  )
})

export default FreeProgramLeadCapture
