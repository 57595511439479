import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import styled, { css } from 'styled-components'

const CountdownBox = styled(Box)`
  ${({ blink }) =>
    blink &&
    css`
      animation: blinker 1s step-start infinite;
    `}

  @keyframes blinker {
    50% {
      background-color: ${({ theme }) => theme.palette.blue.light2};
    }
  }
`

const Countdown = ({ len, countdown, text }) => {
  const countdownBoxes =
    countdown && new Array(len).fill(true).map((a, i) => i < countdown)
  return (
    <Box mt={'1em'} textAlign={'center'}>
      <Typography variant={'subnote'}>{text}</Typography>
      <Box mt={'0.75em'}>
        {countdownBoxes.map((b, i) => (
          <CountdownBox
            key={i}
            blink={i === countdown - 1}
            bgcolor={b ? 'green.0' : 'blue.light2'}
            width={'0.5em'}
            height={'1em'}
            display={'inline-block'}
            mr={'0.25em'}
          />
        ))}
      </Box>
    </Box>
  )
}

export const CountdownEmailSupportTime = ({ len, countdown }) => {
  return (
    <Countdown
      len={len}
      countdown={countdown}
      text={
        <>
          <strong>
            {countdown} day{countdown !== 1 && 's'}
          </strong>{' '}
          with free email support remaining
        </>
      }
    />
  )
}

export const CountdownEmailSupport = ({ len, countdown }) => {
  return (
    <Countdown
      len={len}
      countdown={countdown}
      text={
        <>
          <strong>
            {countdown} programme{countdown !== 1 && 's'}
          </strong>{' '}
          with free email support remaining
        </>
      }
    />
  )
}

export default Countdown
