import React from 'react'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import { graphql, useStaticQuery } from 'gatsby'
import FreeProgramLeadCapture from 'src/components/LeadCapture/FreeProgramLeadCapture'

const FreeProgrammeLandingPage = ({ location }) => {
  const formRef = React.useRef(null)

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "hvco-free-weight-loss-programme.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <Seo
        title="WildLife Weight Loss Coaching"
        description={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        shortTeaser={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        locationPathname={location.pathname}
      />

      <FreeProgramLeadCapture ref={formRef} bgcolor={'blue.light2'} />
    </PageLayout>
  )
}

export default FreeProgrammeLandingPage
